<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.65086 2.42862C4.65086 1.87633 4.20314 1.42862 3.65086 1.42862C3.09857 1.42862 2.65086 1.87633 2.65086 2.42862V2.63833C1.90197 3.01748 1.40308 3.80793 1.40308 4.68955C1.40308 5.57117 1.90197 6.36162 2.65086 6.74077V13.7075C2.65086 14.2598 3.09857 14.7075 3.65086 14.7075C4.20314 14.7075 4.65086 14.2598 4.65086 13.7075V6.74065C5.39962 6.36147 5.89842 5.57108 5.89842 4.68955C5.89842 3.80802 5.39962 3.01763 4.65086 2.63845V2.42862Z" fill="currentColor"/>
    <path d="M9.0177 2.42867C9.0177 1.87639 8.56999 1.42867 8.0177 1.42867C7.46542 1.42867 7.0177 1.87639 7.0177 2.42867V9.08004C6.26928 9.45934 5.77075 10.2495 5.77075 11.1308C5.77075 12.0121 6.26928 12.8023 7.0177 13.1816V13.7075C7.0177 14.2598 7.46542 14.7075 8.0177 14.7075C8.56999 14.7075 9.0177 14.2598 9.0177 13.7075V13.1824C9.76693 12.8033 10.2661 12.0127 10.2661 11.1308C10.2661 10.249 9.76693 9.45833 9.0177 9.07931V2.42867Z" fill="currentColor"/>
    <path d="M13.3845 2.42867C13.3845 1.87639 12.9368 1.42867 12.3845 1.42867C11.8323 1.42867 11.3845 1.87639 11.3845 2.42867V3.92652C10.6355 4.3056 10.1365 5.09614 10.1365 5.97788C10.1365 6.85962 10.6355 7.65016 11.3845 8.02924V13.7075C11.3845 14.2598 11.8323 14.7075 12.3845 14.7075C12.9368 14.7075 13.3845 14.2598 13.3845 13.7075V8.02884C14.1332 7.64959 14.6318 6.8593 14.6318 5.97788C14.6318 5.09646 14.1332 4.30617 13.3845 3.92692V2.42867Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSlidersV'
}
</script>
